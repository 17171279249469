import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Iframe from 'react-iframe'

import './App.css';
import logo from './logo.svg';

function App () {
  let {url} = useParams ();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
      <Iframe
        url={`https://21891433.hs-sites.com/${url}`}
        width={windowSize.innerWidth}
        height={windowSize.innerHeight}
        id=""
        className=""
        display="block"
        position="relative"
      />
  );
}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default App;
