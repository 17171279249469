import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';

export default function Routing() {

    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path='/' element={<App />} />
            <Route path='/:url' element={<App />} />
          </Routes>
        </div>
      </Router>
      
    );
  }